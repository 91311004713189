export interface EnvironmentData {
  run: keyof typeof environmentData;
  development: EnvironmentConfig;
  staging: EnvironmentConfig;
  production: EnvironmentConfig;
}

export interface EnvironmentConfig {
  clientId: string;
  authority: string;
  tenantId: string;
  "api-scope": string;
  appInsightConnectionString: string;
  gpt3key:string;
  gpt4key:string;
  chatApiUrl?: string;
  plotApiUrl?: string;
}
export const environmentData = {
  run: "production",

  development: {
    clientId: "cab2a9a7-593b-4088-a149-3587057dc8c0",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid cab2a9a7-593b-4088-a149-3587057dc8c0/User.Impersonation",
    appInsightConnectionString:
      "InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    gpt3key: "gpt-35-turbo-test",
    gpt4key: "gpt-4o",
    chatApiUrl: "https://app-magai-test-planninginfo-a8gyamh3dqgve0h7.swedencentral-01.azurewebsites.net",
    plotApiUrl: "https://app-magaiplotgen-internal-cfbehyamgbexane4.swedencentral-01.azurewebsites.net"
  },
  staging: {
    clientId: "cab2a9a7-593b-4088-a149-3587057dc8c0",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid cab2a9a7-593b-4088-a149-3587057dc8c0/User.Impersonation",
    appInsightConnectionString:
      "InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    gpt3key: "gpt-35-turbo-test",
    gpt4key: "gpt-4o",
    chatApiUrl: "https://app-magai-dev-a4e5cghjb2dsg2h2.swedencentral-01.azurewebsites.net",
    plotApiUrl: "https://app-magaiplotgen-test-d5ghdvgkgmhcf4fq.swedencentral-01.azurewebsites.net"
  },
  production: {
    clientId: "cab2a9a7-593b-4088-a149-3587057dc8c0",
    authority:
      "https://login.microsoftonline.com/30f52344-4663-4c2e-bab3-61bf24ebbed8",
    tenantId: "30f52344-4663-4c2e-bab3-61bf24ebbed8",
    "api-scope":
      "openid cab2a9a7-593b-4088-a149-3587057dc8c0/User.Impersonation",
    appInsightConnectionString:
      "InstrumentationKey=514eaa1c-389c-4bd0-9ae5-68a89872bdea;IngestionEndpoint=https://westeurope-5.in.applicationinsights.azure.com/;LiveEndpoint=https://westeurope.livediagnostics.monitor.azure.com/",
    gpt3key: "gpt35_8K",
    gpt4key: "gpt-4o",
    chatApiUrl: "https://app-magai-prod-001-gdgwdjhjh4h2dqek.swedencentral-01.azurewebsites.net",
    plotApiUrl: "https://app-magaiplotgen-prod-bge0efgye6ayf7ct.swedencentral-01.azurewebsites.net"
  },
} as EnvironmentData;
