import { useRef, useState, useEffect } from "react";
import mobstyles from "./MobileChat.module.css";
import * as sdk from "microsoft-cognitiveservices-speech-sdk";
import { FaFolder } from "react-icons/fa";
import {
  DefaultButton,
  DirectionalHint,
  IDropdownOption,
  Modal,
  PrimaryButton,
  Stack,
  TextField,
  Tooltip,
  TooltipHost
} from "@fluentui/react";
import {
  DismissRegular,
  SquareRegular,
  ErrorCircleRegular,
  Speaker228Filled,
  SpeakerMute28Regular
} from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import styles from "./Chat.module.css";
import logo from "../../assets/images/login-logo1.png";

import {
  ChatMessage,
  ConversationRequest,
  conversationApi,
  salesConversationApi,
  Citation,
  ToolMessageContent,
  ChatResponse,
  getUserInfo,
  invokeApi,
  sendEmaiApi,
  invokeApi1
} from "../../api";
import { Answer } from "../../components/Answer";
import { QuestionInput } from "../../components/QuestionInput";
import {
  SystemAnswer,
  SystemResponse
} from "../../components/Answer/SystemAnswer";
import React from "react";
import { useMsal } from "@azure/msal-react";
import AppHeader from "../../components/AppHeader";
import { GetRoles, GetUserName } from "../../services/AuthUser";
import RecommendationTab from "../../components/Recommendation/RecommendationTab";
import { useAppContext } from "../../components/ContextAndProvider/Context";
import {
  CButton,
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CModal,
  CPopover,
  CRow
} from "@coreui/react";
import { getInitials } from "../../components/CommonUtils/CommonUtils";
import {
  setJsonItem,
  getAllItems,
  generateSessionId
} from "../../services/LocalStorageService";
import QueSuggestion from "./QueSuggestion";
import MobileDropdown from "./MobileDropdown";
import GramerlyContent from "./GramerlyContent";
import {
  Button,
  Divider,
  Drawer,
  Dropdown,
  MenuProps,
  notification
} from "antd";
import { sample } from "lodash";
import { ChartRepresentation } from "../../components/Answer/ChartRepresentation";
import { useLocation } from "react-router-dom";
import historyimg from "../../assets/images/svgs/history.svg";
import newconversationimg from "../../assets/images/svgs/new-conversation.svg";
import PromptGallery from "../promptGallery/PromptGallery";
interface ChildProps {
  childFunction: () => void;
}
const SalesChat = () => {
  const {
    isSidebarOpen,
    setSidebarOpen,
    selectedSystem,
    setSelectedSystem,
    activeRecommendation,
    setActiveRecommendation,
    isMobile,
    setIsMobile,
    popoverVisible,
    isFocusTextField,
    setIsFocusTextField,
    gramerlyContent,
    setGramerlyContent,
    setActiveGrammar,
    answers,
    setAnswers,
    grammarPopVisible,
    setGrammarlyPopVisible,
    activeGrammar,
    sampleQnPopVisible,
    setSampleQnPopVisible,
    modelValue,
    setModelValue,
    maxResponse,
    setMaxResponse,
    temperature,
    setTemperature,
    topP,
    setTopP,
    pastMessage,
    setPastMessage,
    isDebugMode,
    isPromptRephraseFlag,
    isAzure,
    isReload,
    setIsReload,
    reloadText,
    setReloadText,
    isLoading,
    setIsLoading,
    isMute,
    setIsMute,
    isSpeechStarted,
    setIsSpeechStarted,
    finalAnswers,
    setFinalAnswers,
    isAppliedChanges,
    setIsAppliedChanges,
    guidelines,
    selectedTempIndex,
    setSelectedTempIndex,
    isIncludeFewShots
  } = useAppContext();
  let userDetails = GetUserName();
  const { instance } = useMsal();
  const [player, updatePlayer] = useState<{
    p: sdk.SpeakerAudioDestination | undefined;
    muted: boolean;
  }>({ p: undefined, muted: false });
  const lastQuestionRef = useRef<string>("");
  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [GrammmarVisibility, setGrammarVisibility] = useState<boolean>(false);

  const [activeCitation, setActiveCitation] =
    useState<
      [
        content: string,
        id: string,
        title: string,
        filepath: string,
        url: string,
        metadata: string
      ]
    >();
  const [isCitationPanelOpen, setIsCitationPanelOpen] =
    useState<boolean>(false);
  // const [answers, setAnswers] = useState<ChatMessage[]>([]);
  const abortFuncs = useRef([] as AbortController[]);
  const [showAuthMessage, setShowAuthMessage] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPsModalOpen, setIsPsModalOpen] = useState(false);
  const [smtpProvider, setSmtpProvider] = useState("smtp.hm.com");
  const [senderEmailID, setSenderEmailID] = useState("user1@hm.com");
  const [appPassword, setAppPassword] = useState("");
  const [receiverEmailID, setReceiverEmailID] = useState("");
  const [subject, setSubject] = useState("");
  const [content, setContent] = useState("");
  const [psscript, setPsScript] = useState("");
  const [isToggled, setIsToggled] = useState(true);
  const [historyTitle, setHistoryTitle] = useState("");
  const [relatedPrompts, setRelatedPrompts] = useState<string[]>([]);
  const [showPrompts, setShowPrompts] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = () => {
    setDrawerVisible(true);
  };

  const hideDrawer = () => {
    setDrawerVisible(false);
  };
  const [indexName, setIndexName] = useState(
    "yourdata-20230609092735-afc9-index-chunk"
  );
  const salesIntelligentPromtQuest = [
    {
      title: "Best Department - Sweden, Week 30 2024",
      description:
        "Which was the best performing department in Sweden during Week 30 2024?"
    },
    {
      title: "Store Sales Comparison - Atlanta, July 2024",
      description:
        "Compare the sales figures for the stores in Atlanta during July 2024?"
    },
    {
      title: "Top 10 Articles - India, Feb 2024",
      description:
        "Which were the top 10 best selling articles in India during the month of February 2024?"
    },
    {
      title: "Top Reasons for Returns - Jersey Basic, June 2024",
      description:
        "What are the top five reasons for product returns in the Jersey Basic department for June 2024?"
    }
  ];
  const options: IDropdownOption[] = [
    { key: "yourdata-20230609092735-afc9-index-chunk", text: "EnterpriseDS" },
    { key: "genaiopsteps", text: "KnowledgeDS" },
    { key: "insightind", text: "Insight" },
    { key: "addmemberinedpoc2", text: "ActionDS" },
    { key: "insightindexnew", text: "Monitoring" }
  ];
  const indexKeyWord: { index: string; keywords: string[] }[] = [
    { index: "addmember-index", keywords: ["add member", "edpoc", "ED-Poc"] }
  ];
  let SampleQuestion: {
    title: string;
    index: string;
    suggestedQuestions: string[];
    indexName?: string;
    description: string;
  }[] = [
    {
      title: "HAI Buddy",
      index: "induction-index",
      suggestedQuestions: [
        "What is HAI Buddy?",
        "Give a summary about H&M in two lines",
        "What is the relationship of TCS with H&M in two lines?",
        "Who is the current CEO of H&M?",
        "Type your own question below"
      ],
      description:
        "HAI(HM.AI) Buddy is a reliable and trustworthy digital friend that can assist users in various aspects of their daily lives."
    },

    {
      title: "Business Impact (P1/Major Incident)",
      indexName: "Business Impact",
      index: "p1-incident-data-index",
      suggestedQuestions: [
        "What are the current ongoing incidents?",
        "What is the business impact of current incidents?",
        "What are the incident numbers where US Store are impacted?",
        "Type your own question below"
      ],
      description:
        "The module shows a severe business impact, causing significant financial losses and disrupting critical operations."
    },
    {
      title: "Operational AI Companion (Vertex)",
      indexName: "Vertex",
      index: "vertex-index",
      suggestedQuestions: [
        "Why Vertex?",
        "What is a VST?",
        "Can you share vendor contact information for Vertex?",
        "Total Incidents received for Vertex in last year?",
        "Current status of vertex application?",
        "Type your own question below"
      ],
      description:
        "Operational AI Companion (Vertex) is a tax engine used by businesses for tax calculation and compliance (United States, Canada)."
    },
    {
      title: "Store Network",
      indexName: "Store Network",
      index: "instorecare-index",
      suggestedQuestions: [
        "Can you share health status of SE0034 ?",
        "Can you share WAN status of SE0145 ?",
        "How many access points are connected in SE0145 ?",
        "Does store  SE0152 has any routers down ?",
        "Type your own question below"
      ],
      description:
        "The Store Network Section is responsible for providing comprehensive updates on the overall health and status of the store network."
    }
  ];
  if (GetRoles().includes("SalesInsights.Users")) {
    SampleQuestion = [
      ...SampleQuestion,
      {
        title: "Sales Intelligent",
        indexName: "Sales Intelligent",
        index: "",
        suggestedQuestions: [
          "Which Sweden store did more sales ?",
          "Which is the most preferred size for adults in US ?",
          "What color product for kids is the best-selling in Sweden ?"
        ],
        description:
          "The Sales Intelligent section provides visibility into the sales process across multiple stores, enabling better analysis and decision-making."
      }
    ];
  }
  console.log("GetRoles()", GetRoles());
  if (GetRoles().includes("Store.Managar")) {
    SampleQuestion = [
      ...SampleQuestion,
      {
        title: "Store Manager",
        index: "store-index",
        suggestedQuestions: [
          "Does start of the day report received for all stores?",
          "Which store has more visitors count yesterday?",
          "Which india store has less visitors day before yesterday ?",
          "Which US store in has more visitors yesterday then day before yesterday?",
          "Type your own question below"
        ],
        description:
          "With AI Companion, store managers can easily manage inventory, track sales, and analyze customer data to make informed business decisions."
      }
    ];
  }
  if (GetRoles().length === 1 && GetRoles()[0] === "BusinessImpact.Users") {
    SampleQuestion = [
      {
        title: "Business Impact (P1/Major Incident)",
        indexName: "Business Impact",
        index: "p1-incident-data-index",
        suggestedQuestions: [
          "What are the current ongoing incidents?",
          "What is the business impact of current incidents?",
          "What are the incident numbers where US Store are impacted?",
          "Type your own question below"
        ],
        description:
          "The module shows a severe business impact, causing significant financial losses and disrupting critical operations."
      }
    ];
  }
  const [selectedOption, setSelectedOption] = useState<string | undefined>(
    "yourdata-20230609092735-afc9-index-chunk"
  );
  const [spokenText, setSpokenText] = useState<string>("");
  const speechConfig = sdk.SpeechConfig.fromSubscription(
    "1221987474544498aeabb29a4ce8167c",
    "westeurope"
  );
  const [selectedIndex, setSelectedIndex] = useState("");
  const [selectedIndexName, setSelectedIndexName] = useState("");
  const [historyData, setHistoryData] = useState<ChatMessage[]>([]);
  const [sessionID, setSessionID] = useState("");
  const [groupedData, setGroupedData] = useState<Record<string, any[]>>({});
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } else {
      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [isOpen]);

  // Function to toggle the popover
  const togglePopover = () => {
    setIsOpen(!isOpen);
  };
  useEffect(() => {
    if (!sessionID) {
      const sessionId = generateSessionId();
      setSessionID(sessionId);
    }
  }, []);
  useEffect(() => {
    if (reloadText) {
      makeApiRequest(reloadText);
      setIsReload(false);
      setReloadText("");
    }
  }, [reloadText]);
  useEffect(() => {
    // Perform setup or subscribe to something
    console.log("Moved to Sales Chat page");
    return () => {
      //clearChat();
      // Perform cleanup here
      console.log("Sale Component is being unmounted");
    };
  }, []);
  const groupByDate = (inputData: any[]) => {
    let ipdata = sortArrayByTimestamp(inputData);
    return ipdata.reduce((result: Record<string, any[]>, item) => {
      const key = Object.keys(item)[0].split("-")[1];
      result[key] = result[key] || [];
      result[key].push(item);
      return result;
    }, {});
  };
  const extractTimestamp = (key: string): number => {
    const parts = key.split("-");
    return Number(parts[2]);
  };
  const sortArrayByTimestamp = (array: any[]): any[] => {
    return array.sort((a, b) => {
      const keyA = Object.keys(a)[0];
      const keyB = Object.keys(b)[0];
      const timestampA = extractTimestamp(keyA);
      const timestampB = extractTimestamp(keyB);
      return timestampB - timestampA;
    });
  };
  const handleSave = () => {
    if (sessionID && answers.length > 0) {
      console.log("sessionID", sessionID, answers);
      setJsonItem(sessionID, answers);
      addHistory();
    }
  };
  let currentSynthesisOperation: any = null;
  useEffect(() => {
    const checkIfMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkIfMobile();
    window.addEventListener("resize", checkIfMobile);
    return () => {
      window.removeEventListener("resize", checkIfMobile);
    };
  }, []);

  const speakText = (text: string) => {
    const myPlayer = new sdk.SpeakerAudioDestination();
    updatePlayer((p) => {
      p.p = myPlayer;
      return p;
    });
    const audioConfig = sdk?.AudioConfig?.fromSpeakerOutput(myPlayer);
    speechConfig.speechSynthesisVoiceName = "en-US-JennyNeural";
    let synthesizer: sdk.SpeechSynthesizer | undefined =
      new sdk.SpeechSynthesizer(speechConfig, audioConfig);
    if (!isMute && synthesizer) {
      synthesizer?.speakTextAsync(
        text?.toString(),
        (result) => {
          let outputText;
          if (
            result?.reason === sdk?.ResultReason?.SynthesizingAudioCompleted
          ) {
            outputText = `Synthesis finished for "${text}".\n`;
          } else if (result?.reason === sdk?.ResultReason?.Canceled) {
            outputText = `Synthesis failed. Error detail: ${result?.errorDetails}.\n`;
          }
          synthesizer!.close();
          setIsSpeechStarted(true);
          myPlayer.onAudioEnd = function () {
            setIsSpeechStarted(false);
          };
        },
        (err) => {
          console.log(`Error: ${err}.\n`);
          synthesizer!.close();
        }
      );
    } else {
      console.log("Synthesizer is undefined or isMute is true.");
    }
  };
  // Function to stop the ongoing speech synthesis.
  async function stopSpeaking() {
    setIsMute(true);
    updatePlayer((p) => {
      if (p?.p) {
        if (!p?.muted) {
          //setIsMute(true);
          p?.p?.mute();
          p?.p?.close();
        } else {
          //setIsMute(false);
          // p.p.resume();
        }
      }
      return { ...p, muted: !p?.muted };
    });
  }

  const handleDropdownChange = (
    event: React.FormEvent<HTMLDivElement>,
    item?: IDropdownOption
  ): void => {
    if (item) {
      setSelectedOption(item?.key as string);
      setIndexName(item?.key as string);
      // Perform any other actions you want based on the selected value
    }
  };
  const showModal = () => setIsModalOpen(true);
  const hideModal = () => setIsModalOpen(false);
  const hidePsModal = () => setIsPsModalOpen(false);
  const _onchangeIndex = () => {
    if (!isToggled) {
      setIndexName("yourdata-20230609092735-afc9-index-chunk");
    } else {
      setIndexName("genaiopsteps");
    }
    setIsToggled(!isToggled); // Toggle the state when the switch is clicked
  };
  const _onchangeMAIndex = () => {
    setIsToggled(!isToggled); // Toggle the state when the switch is clicked
  };

  const showEmailModal = () => {
    if (
      answers?.length > 0 &&
      answers[answers?.length - 1]?.role == "assistant"
    ) {
      const lines = answers[answers?.length - 1]?.content?.split("\n");
      const tempcontent = answers[answers?.length - 1]?.content;
      const subjectIndex = tempcontent?.indexOf("Subject:");
      let subject = "";
      let content = "";

      if (subjectIndex !== -1) {
        subject = tempcontent
          ?.substring(
            subjectIndex + "Subject:"?.length,
            tempcontent?.indexOf("\n", subjectIndex)
          )
          ?.trim();

        // Extract the content after the subject
        content = tempcontent
          ?.substring(tempcontent?.indexOf("\n", subjectIndex) + 1)
          ?.trim();
      }

      setSubject(subject);
      setContent(content);
    }
    setIsModalOpen(true);
  };
  function getLastAssistantRole(data: any[]): any | null {
    for (let i = data?.length - 1; i >= 0; i--) {
      if (data[i]?.role === "assistant") {
        return data[i];
      }
    }
    return null;
  }
  useEffect(() => {
    if (isAppliedChanges) {
      const systemMessage: ChatMessage = {
        role: "newConversation",
        content: ""
      };
      setAnswers([...answers, systemMessage]);
      setIsAppliedChanges(false);
    }
  }, [isAppliedChanges]);
  const onExecute = async () => {
    const response = await invokeApi(psscript);
    const systemMessage: ChatMessage = {
      role: "systemAnswer",
      content: "Powershell Script Executed Successfully"
    };
    setAnswers([...answers, systemMessage]);
    hidePsModal();
  };
  const onSend = async () => {
    const script = `$smtpServer = "${smtpProvider}"
    $smtpPort = "587"
    $username = "${senderEmailID}"
    $password = "${appPassword}"
    $to = "${receiverEmailID}"
    $subject = "${subject}"
    $body = "${content}"
    $from =  "${senderEmailID}"
    
    $smtp = New-Object System.Net.Mail.SmtpClient($smtpServer, $smtpPort)
    $smtp.EnableSSL = $true
    $smtp.Credentials = New-Object System.Net.NetworkCredential($username, $password)
    $mailMessage = New-Object System.Net.Mail.MailMessage($from, $to, $subject, $body)
    $smtp.Send($mailMessage)`;

    const response = await sendEmaiApi(script);
    const systemMessage: ChatMessage = {
      role: "systemAnswer",
      content: "Email Sent Successfully"
    };
    setAnswers([...answers, systemMessage]);
    hideModal();
  };
  const getUserInfoList = async () => {
    const userInfoList = await getUserInfo();
    if (
      userInfoList?.length === 0 &&
      window?.location?.hostname !== "127.0.0.1"
    ) {
      setShowAuthMessage(true);
    } else {
      setShowAuthMessage(false);
    }
  };
  const successFunc = async (ans: ChatMessage[], email: string) => {
    const response = await invokeApi1(email);
    const updatedData = ans?.map((obj) => {
      if (obj?.role === "systemQuestion") {
        return {
          role: "systemAnswer",
          content: obj?.email + " added successfully"
        };
      }
      return obj;
    });
    setAnswers(updatedData);
  };
  const cancelFunc = async (ans: ChatMessage[], email: string) => {
    const response = await invokeApi(email);
    const updatedData = ans?.filter((obj) => obj?.role !== "systemQuestion");
    setAnswers(updatedData);
  };
  const noAnsFunc = async (ans: ChatMessage[], email: string) => {
    const response = await invokeApi(email);
    const updatedData = ans?.map((obj) => {
      if (obj?.role === "systemQuestion") {
        return {
          role: "systemQuestion",
          content:
            "If you want to add others email address, Please enter the email and send",
          callback: successFunc,
          noAnsCallback: cancelFunc,
          email: ""
        };
      }
      return obj;
    });
    setAnswers(updatedData);
  };

  const replaceINCWithLink = (content: string): string => {
    const words = content.split(/\b/);
    const updatedWords = words.map((word) =>
      word.includes("INC")
        ? `[${word}](https://hm.service-now.com/nav_to.do?uri=incident.do?sysparm_query=number=${word.replace(
            /[^\w\s]/g,
            ""
          )})`
        : word
    );
    return updatedWords.join("");
  };
  const replaceLinkWithINC = (content: string): string => {
    return content.replace(
      /\[([A-Z]+\d+)\]\(https:\/\/hm\.service-now\.com\/nav_to\.do\?uri=incident\.do\?sysparm_query=number=\1\)/g,
      "$1"
    );
  };
  const replaceUpDownWithLink = (content: string): string => {
    const words = content.split(/\b/);
    console.log("##########Content", content);
    const updatedWords = words.map((word) =>
      word.trim() == "Up" || word.trim() == "Green"
        ? `${word} ![GEllipse SVG](/images/GEllipse.svg)`
        : word.trim() == "Down" || word.trim() == "Red"
        ? `${word} ![REllipse SVG](/images/REllipse.svg)`
        : word
    );
    return updatedWords.join("");
  };
  const getLabels = (): string[] => {
    const labels: string[] = [];

    // Iterate over each category (Rephrase and AdjustTone)
    for (const category of Object.values(gramerlyContent)) {
      // Iterate over items in the category
      for (const item of category) {
        // If value is true, push the label to the array
        if (item.value) {
          labels.push(item.label);
        }
      }
    }

    return labels;
  };
  const getIndexName = (question: string) => {
    let tempidx = "";
    indexKeyWord.forEach((element) => {
      let hasMatch = element.keywords.some((word) => {
        let regex = new RegExp("\\b" + word + "\\b", "i");
        return regex.test(question);
      });
      if (hasMatch) {
        tempidx = element.index;
      }
    });
    return tempidx;
  };
  function extractLastConversation(jsonData: ChatMessage[]) {
    //const { messages } = jsonData;
    let newConversationIndex = -1;

    // Find the last occurrence of 'newConversatin' role
    jsonData.forEach((message, index) => {
      if (message.role === "newConversation") {
        newConversationIndex = index;
      }
    });

    // If 'newConversatin' is found, return the last part of the conversation after it
    if (newConversationIndex !== -1) {
      return jsonData.slice(newConversationIndex + 1); // Return only the part after 'newConversatin'
    }

    // Return an empty array if no 'newConversatin' is found
    return jsonData;
  }
  const repostQuestion = (Selectedgramerlycontent: string) => {
    setGrammarlyPopVisible(false);
    makeApiRequest(
      lastQuestionRef.current,
      selectedIndex,
      Selectedgramerlycontent
    );
  };
  const containsWord = (text: string, word: string): boolean => {
    const regex = new RegExp(`\\b${word}\\b`, "i"); // Case-insensitive word boundary search
    return regex.test(text);
  };
  const replaceChartWithTableFormat = (question: string): string => {
    const regex = /in\s+chart/gi; // Case-insensitive regular expression
    return question.replace(regex, "in table format with 2 columns");
  };
  const replaceChartWithTableFormat2 = (question: string): string => {
    const regex = /in\s+charts/gi; // Case-insensitive regular expression
    return question.replace(regex, " in table format with 2 columns");
  };
  const makeApiRequest = async (
    question: string,
    idx?: string,
    selectedGramerly?: string
  ) => {
    const regex = /@hm\.com\b/i;
    const lastanswer = answers[answers?.length - 1];
    if (
      question.match(regex) &&
      lastanswer &&
      lastanswer.content ==
        "If you want to add others email address, Please enter the email and send"
    ) {
      const regex2 = /\b(\S+@hm\.com)\b/;
      const match = question?.match(regex2);
      const wordWithHmEmail = match ? match[1] : "";
      const response = await invokeApi(wordWithHmEmail);
      const updatedData = answers?.map((obj) => {
        if (obj?.role === "systemQuestion") {
          return {
            role: "systemAnswer",
            content: wordWithHmEmail + " added successfully"
          };
        }
        return obj;
      });
      setAnswers(updatedData);
    } else {
      lastQuestionRef.current = question;

      setIsLoading(true);
      setShowLoadingMessage(true);
      const abortController = new AbortController();
      abortFuncs?.current?.unshift(abortController);
      let trueGrammerlyContent = getLabels();
      let gramerlyresult = trueGrammerlyContent.map((item) => {
        if (item) {
          return `\n- "${item}"`;
        } else {
          return "";
        }
      });
      let gramerContent = selectedGramerly ? selectedGramerly : activeGrammar;
      const finalqn =
        question + " GrammerlyContent " + "\n- " + '"' + gramerContent + '"';
      const userMessage: ChatMessage = {
        role: "user",
        content: gramerContent ? finalqn : question
      };
      const filteredAnswers: ChatMessage[] = answers?.filter(
        (item) =>
          item?.role !== "systemAnswer" && item?.role !== "systemQuestion"
      );
      const request: ConversationRequest = {
        messages: [...filteredAnswers, userMessage]
      };
      let previousUserMessage = "";
      const newMessage = extractLastConversation(request.messages);

      const filteredMessages: any[] = newMessage.map((message: any) => {
        if (
          message.role === "user" &&
          message?.content?.trim()?.toLowerCase() == "in charts"
        ) {
          return {
            ...message,
            content: previousUserMessage
              ? previousUserMessage.replace(
                  "in table format with 2 columns",
                  ""
                ) + " in table format with 2 columns"
              : message.content
          };
        }
        if (
          message.role === "user" &&
          containsWord(message.content, "in charts")
        ) {
          return {
            ...message,
            content: replaceChartWithTableFormat2(message.content)
          };
        }
        if (
          message.role === "user" &&
          containsWord(message.content, "in chart")
        ) {
          return {
            ...message,
            content: replaceChartWithTableFormat(message.content)
          };
        }
        if (
          message.role === "user" &&
          message.content.includes("GrammerlyContent")
        ) {
          // Replace "GrammerlyContent" with an empty string
          return {
            ...message,
            content: message.content.replace("GrammerlyContent", "")
          };
        }
        if (message.role === "user") {
          previousUserMessage = message.content;
        }
        if (
          message.role === "assistant" &&
          (message.content.includes("![GEllipse SVG](/images/GEllipse.svg)") ||
            message.content.includes("![REllipse SVG](/images/REllipse.svg)"))
        ) {
          // Replace "GrammerlyContent" with an empty string
          let modifiedContent = message.content;

          // Define the substrings to replace
          const substringsToReplace = [
            "![GEllipse SVG](/images/GEllipse.svg)",
            "![REllipse SVG](/images/REllipse.svg)"
          ];

          // Iterate over each substring and replace all occurrences
          substringsToReplace.forEach((substring) => {
            const regex = new RegExp(
              substring.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
              "g"
            );
            modifiedContent = modifiedContent.replace(regex, "");
          });
          return {
            ...message,
            content: modifiedContent
          };
        }
        if (
          message.role === "assistant" &&
          message.content.includes("https://hm.service-now.com/nav_to.do?")
        ) {
          let modifiedContent = replaceLinkWithINC(message.content);
          return {
            ...message,
            content: modifiedContent
          };
        }
        if (message.role === "assistant" && message.sql) {
          let modifiedContent = `SQl_Query- ${message.sql} \n\n user_response - ${message.content}  `;
          return {
            ...message,
            content: modifiedContent
          };
        }
        return message;
      });

      // Assign the filtered messages back to a ConversationRequest object
      const filteredRequest: ConversationRequest = {
        messages: filteredMessages
      };
      function trimStringToLines(inputString: string, lineLimit: number) {
        const lines = inputString?.split("\n");
        if (lines?.length <= lineLimit) {
          return inputString;
        } else {
          return lines?.slice(0, lineLimit)?.join("\n");
        }
      }
      function trimStringToWords(inputString: string, wordLimit: number) {
        const words = inputString?.split(/\s+/);
        if (words?.length <= wordLimit) {
          return inputString;
        } else {
          return words?.slice(0, wordLimit)?.join(" ");
        }
      }
      let result = {} as ChatResponse;
      let containsRoleToolAndContent = false;
      try {
        let defaultques = [
          "ed-poc",
          "edpoc",
          "Enterprise dashboard access",
          "SOP for ED",
          "dashboard acces",
          "Enterprise dashboard",
          "SharePoint",
          "mailbox"
        ];
        let hasMatch = defaultques?.some((word) => {
          let regex = new RegExp("\\b" + word + "\\b", "i");
          return regex?.test(question);
        });
        let index = hasMatch
          ? "genaiopsteps"
          : "yourdata-20230609092735-afc9-index-chunk";
        let selectedIdx = idx ? idx : selectedIndex;
        let actionidx = getIndexName(question);
        if (actionidx) {
          selectedIdx = actionidx;
        }
        try {
          const response = await salesConversationApi(
            filteredRequest,
            abortController?.signal,
            modelValue,
            maxResponse,
            temperature,
            topP,
            pastMessage,
            isDebugMode,
            isAzure,
            isPromptRephraseFlag,
            guidelines,
            (idx? idx : selectedTempIndex), 
            isIncludeFewShots
          );
          if (!response.ok) {
            throw new Error(`API returned status ${response.status}`);
          }
          if (response?.body) {
            setSelectedTempIndex("");
            const reader = response?.body?.getReader();
            let runningText = "";
            while (true) {
              const { done, value } = await reader?.read();
              if (done) break;

              var text = new TextDecoder("utf-8")?.decode(value);
              const objects = text?.split("\n");

              let lastmsg = "";
              objects?.forEach((obj, i) => {
                try {
                  runningText += obj;
                  result = JSON.parse(runningText);
                  setShowLoadingMessage(false);

                  const updatedMessages = result.choices[0].messages.map(
                    (message) => {
                      message.sql = result?.SQL_Query;
                      message.rephrased_user_prompt =
                        result?.rephrased_user_prompt;
                      message.Overall_Latency = result?.Overall_Latency;
                      message.fetch_latency = result?.fetch_latency;
                      message.total_tokens = result?.total_tokens;
                      message.table_names = result?.table_names;
                      message.db_data_fetched_size_in_tokens =
                        result?.db_data_fetched_size_in_tokens;
                      message.query_generation_latency =
                        result?.query_generation_latency;
                      message.response_generation_latency =
                        result?.response_generation_latency;
                      message.index_classification_latency =
                        result?.index_classification_latency;
                      if (
                        message.role === "assistant" &&
                        message.content.includes("INC")
                      ) {
                        return {
                          ...message,
                          content: replaceINCWithLink(message.content)
                        };
                      }
                      if (message.role === "assistant") {
                        if (
                          message?.content
                            ?.toLowerCase()
                            ?.includes("related:") ||
                          message?.content
                            ?.toLowerCase()
                            ?.includes("related prompts:")
                        ) {
                          const { mainText, relatedPrompts } =
                            extractTextAndRelatedPrompts(message.content);
                          setRelatedPrompts(relatedPrompts);
                          return {
                            ...message,
                            content: mainText
                          };
                        } else {
                          setRelatedPrompts([]);
                        }
                      }
                      // const regex = /(Up|Green|Down|Red)/gi;
                      // const containsWord = regex.test(message.content);

                      // if (message.role === "assistant" && containsWord) {
                      //   let modifiedContent = message.content;

                      //   // Define the substrings to replace
                      //   const substringsToReplace = [
                      //     "![GEllipse SVG](/images/GEllipse.svg)",
                      //     "![REllipse SVG](/images/REllipse.svg)"
                      //   ];

                      //   // Iterate over each substring and replace all occurrences
                      //   substringsToReplace.forEach((substring) => {
                      //     const regex = new RegExp(
                      //       substring.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
                      //       "g"
                      //     );
                      //     modifiedContent = modifiedContent.replace(regex, "");
                      //   });

                      //   return {
                      //     ...message,
                      //     content: replaceUpDownWithLink(modifiedContent)
                      //   };
                      // }
                      return message;
                    }
                  );

                  setAnswers([...answers, userMessage, ...updatedMessages]);
                  let responsemsg =
                    result &&
                    result?.choices &&
                    result?.choices[0] &&
                    result?.choices[0]?.messages
                      ? result?.choices[0]?.messages
                      : "";

                  runningText = "";
                  if (result?.references?.includes("AddMember3.docx")) {
                    containsRoleToolAndContent = true;
                  }

                  // for (const choice of result?.choices) {
                  //   for (const message of choice?.messages) {
                  //     if (
                  //       message?.role === "tool" &&
                  //       message?.content?.includes("AddMember3.docx")
                  //     ) {
                  //       containsRoleToolAndContent = true;
                  //       break;
                  //     }
                  //   }
                  // }
                } catch {}
              });
            }
          }
        } catch (error: any) {
          // Show error notification
          notification.error({
            message: "Error",
            description: `Something went wrong: ${error.message}`
          });
          setAnswers([...answers, userMessage]);
        }
      } catch (e) {
        if (!abortController?.signal?.aborted) {
          console.error(e);
          console.error(result);
        }
        setAnswers([...answers, userMessage]);
      } finally {
        try {
          if (containsRoleToolAndContent) {
            let userEmail = sessionStorage?.getItem("userSessionEmail") || "";

            const systemMessage: ChatMessage = {
              role: "systemQuestion",
              content:
                "Do you want to add your emailid " +
                userEmail +
                " in ED-POC-Users?",
              callback: successFunc,
              noAnsCallback: noAnsFunc,
              email: userEmail
            };
            setAnswers([
              ...answers,
              userMessage,
              ...result?.choices[0]?.messages,
              systemMessage
            ]);
            const regex = /@hm\.com\b/i;
            if (question.match(regex)) {
              const regex2 = /\b(\S+@hm\.com)\b/;
              const match = question.match(regex2);
              const wordWithHmEmail = match ? match[1] : null;
              const systemMessage: ChatMessage = {
                role: "systemQuestion",
                content:
                  "Do you want to add " + wordWithHmEmail + " in ED-POC-Users?",
                callback: successFunc,
                noAnsCallback: noAnsFunc,
                email: wordWithHmEmail ? wordWithHmEmail : ""
              };
              setAnswers([
                ...answers,
                userMessage,
                ...result.choices[0].messages,
                systemMessage
              ]);
            }
          }
          containsRoleToolAndContent = false;
          setIsLoading(false);
          setShowLoadingMessage(false);

          abortFuncs.current = abortFuncs?.current?.filter(
            (a) => a !== abortController
          );
          let finalmessage = "";
          console.log("Results:", result);
          setFinalAnswers(result?.choices[0]?.messages);
          result?.choices[0]?.messages?.forEach((element) => {
            if (element?.role == "assistant") {
              finalmessage = element?.content;
            }
          });
          if (finalmessage) {
            if (finalmessage?.length <= 500) {
              speakText(finalmessage);
            }
          }

          console.log("result", result);
        } catch (e) {}
      }

      return abortController?.abort();
    }
  };

  const clearChat = () => {
    console.log("Clearing chat");
    updatePlayer((p) => {
      console.log("updatePlayer");
      if (p?.p) {
        console.log("if (p?.p)");
        if (!p?.muted) {
          console.log("p?.p?.mute();");
          //setIsMute(true);
          p?.p?.mute();
          p?.p?.close();
        } else {
          //setIsMute(false);
          // p.p.resume();
        }
      }
      return { ...p, muted: !p?.muted };
    });
    handleSave();
    lastQuestionRef.current = "";
    setActiveCitation(undefined);
    setAnswers([]);
    setSelectedIndex("");
    setSelectedIndexName("");
    const sessionId = generateSessionId();
    setSessionID(sessionId);
    setSelectedSystem("");
    setSidebarOpen(false);
  };

  const stopGenerating = () => {
    abortFuncs?.current?.forEach((a) => a?.abort());
    setShowLoadingMessage(false);
    setIsLoading(false);
  };

  useEffect(() => {
    getUserInfoList();
  }, []);
  useEffect(() => {
    handleSave();
  }, [answers]);

  useEffect(
    () => chatMessageStreamEnd?.current?.scrollIntoView({ behavior: "smooth" }),
    [showLoadingMessage]
  );
  const onSuggestionQNClick = (
    question: string,
    indexName: string,
    index: string
  ) => {
    console.log("IndexName", index);
    if (question != "Type your own question below") {
      setSelectedIndex(index);
      setSelectedIndexName(indexName);
      makeApiRequest(question, index);
    } else {
      setSelectedIndex(index);
      setSelectedIndexName(indexName);
      // const elements = document.querySelectorAll(".dropdown-content");
      // elements.forEach((element) => {
      //   console.log("Element", element);
      //   (element as HTMLElement).style.display = "none";
      // });
      setIsFocusTextField(true);
    }
  };
  const onSampleQNClick = (item: {
    title: string;
    indexName?: string;
    index: string;
    description: string;
  }) => {
    setSelectedIndex(item?.index);
    setSelectedSystem(item?.title);
    setSelectedIndexName(item?.indexName ? item?.indexName : item?.title);
  };
  const onShowCitation = (citation: Citation) => {
    setActiveCitation([
      citation?.content,
      citation?.id,
      citation?.title ?? "",
      citation?.filepath ?? "",
      "",
      ""
    ]);
    setIsCitationPanelOpen(true);
  };
  const extractTextAndRelatedPrompts = (
    text: string
  ): { mainText: string; relatedPrompts: string[] } => {
    // Define a regex pattern to split the text into main content and related questions section
    const pattern =
      /\s*(?:\*\*\s*Related\s*:\s*\*\*|###\s*Related:\s*|Related:\s*|Related\s*Prompts\s*:\s*)\s*/i;

    // Split the text into main content and related questions section based on the pattern
    const [mainText, relatedSection] = text.split(pattern);

    // Initialize an empty array for related prompts
    let relatedPrompts: string[] = [];

    // If the related section exists
    if (relatedSection) {
      console.log("Related section:", relatedSection);
      // Process related questions, handling both numbered and bulleted lists
      relatedPrompts = relatedSection
        .trim() // Remove extra whitespace from the start and end
        .split(/\n\s*\d+\.\s*|\n\s*[-•]\s*/) // Split by newline and optional numbers or dashes/bullets with spaces
        .filter((q) => q.trim()) // Filter out empty strings
        .map((q) => q.replace(/^\d+\.\s*|^[-•]\s*/, "").trim()); // Trim each question
    }

    return {
      mainText: mainText ? mainText.trim() : "", // Ensure mainText is not null or undefined
      relatedPrompts
    };
  };
  const parseCitationFromMessage = (message: ChatMessage) => {
    if (message?.role === "tool") {
      try {
        const toolMessage = JSON.parse(message?.content) as ToolMessageContent;
        return toolMessage?.citations;
      } catch {
        return [];
      }
    }
    return [];
  };
  const setHistoryToCurrent = (
    historydata: ChatMessage[],
    historyTitle: string
  ) => {
    setSessionID(historyTitle);
    setAnswers(historydata);
    lastQuestionRef.current = "History added";
  };
  const sortDateKeysDescending = (input: {
    [key: string]: any[];
  }): { [key: string]: any[] } => {
    const sortedKeys = Object.keys(input).sort((a, b) => {
      const dateA = new Date(a);
      const dateB = new Date(b);
      return dateB.getTime() - dateA.getTime();
    });

    const sortedObject: { [key: string]: any[] } = {};
    for (const key of sortedKeys) {
      sortedObject[key] = input[key];
    }

    return sortedObject;
  };
  const addHistory = () => {
    let historydetails = getAllItems();
    const grouped = sortDateKeysDescending(groupByDate(historydetails));
    setGroupedData(grouped);
    //setActiveRecommendation(buttonName);
    //setSidebarOpen(true);
  };
  const handleRecommendationClick = (buttonName: string) => {
    if (!isSidebarOpen) {
      let historydetails = getAllItems();
      const grouped = sortDateKeysDescending(groupByDate(historydetails));
      setGroupedData(grouped);
      setActiveRecommendation(buttonName);
      setSidebarOpen(true);
    } else {
      setSidebarOpen(false);
    }
  };
  let haiBuddyQues: string[] = [
    "What is HAI Buddy?",
    "Give a summary about H&M in two lines",
    "What is the relationship of TCS with H&M in two lines?",
    "Who is the current CEO of H&M?",
    "Type your own question below"
  ];
  const businessImpactQues: string[] = [
    "What are the current ongoing incidents?",
    "What is the business impact of current incidents?",
    "What are the incident numbers where US Store are impacted?",
    "Type your own question below"
  ];
  const storeManagerQues: string[] = [
    "Does start of the day report received for all stores?",
    "Which store has more visitors count yesterday?",
    "Which india store has less visitors day before yesterday ?",
    "Which US store in has more visitors yesterday then day before yesterday?",
    "Type your own question below"
  ];
  const vertexQues: string[] = [
    "Why Vertex?",
    "What is a VST?",
    "Can you share vendor contact information for Vertex?",
    "Total Incidents received for Vertex in last year?",
    "Current status of vertex application?",
    "Type your own question below"
  ];
  const storeNetworkQues: string[] = [
    "Can you share health status of SE0034 ?",
    "Can you share WAN status of SE0145 ?",
    "How many access points are connected in SE0145 ?",
    "Does store  SE0152 has any routers down ?",
    "Type your own question below"
  ];
  const salesIntelligenceQues: string[] = [
    "Which Sweden store did more sales ?",
    "Which is the most preferred size for adults in US ?",
    "What color product for kids is the best-selling in Sweden ?"
  ];
  const { isDarkTheme, setIsDarkTheme } = useAppContext();
  const toggleTheme = (data: any) => {
    console.log("Data", data);
    // setIsDarkTheme(data);
  };
  const location = useLocation();
  const haiBuddy: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          {haiBuddyQues?.map((item, index) => (
            <div
              className={`${styles?.singleQue} ${"d-flex align-items-center"} ${
                isDarkTheme ? "darkSingleQue" : ""
              }`}
              key={index}
              onClick={() =>
                onSuggestionQNClick(item, "HAI Buddy", "induction-index")
              }
            >
              {item}
              {item === "Type your own question below" && (
                <span className="spriteUrl belowIcon"></span>
              )}
            </div>
          ))}
        </>
      )
    }
  ];
  const busiImpact: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          {businessImpactQues?.map((item, index) => (
            <div
              className={`${
                styles?.singleQue + " " + "d-flex align-items-center"
              } ${isDarkTheme ? "darkSingleQue" : ""}`}
              key={index}
              onClick={() =>
                onSuggestionQNClick(
                  item,
                  "Business Impact",
                  "p1-incident-data-index"
                )
              }
            >
              {item}
              {item === "Type your own question below" && (
                <span className="spriteUrl belowIcon"></span>
              )}
            </div>
          ))}
        </>
      )
    }
  ];
  const strMng: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          {storeManagerQues?.map((item, index) => (
            <div
              className={`${
                styles?.singleQue + " " + "d-flex align-items-center"
              } ${isDarkTheme ? "darkSingleQue" : ""}`}
              key={index}
              onClick={() =>
                onSuggestionQNClick(item, "Store Manager", "store-index")
              }
            >
              {item}
              {item === "Type your own question below" && (
                <span className="spriteUrl belowIcon"></span>
              )}
            </div>
          ))}
        </>
      )
    }
  ];
  const vertex: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          {vertexQues?.map((item, index) => (
            <div
              className={`${
                styles?.singleQue + " " + "d-flex align-items-center"
              } ${isDarkTheme ? "darkSingleQue" : ""}`}
              key={index}
              onClick={() =>
                onSuggestionQNClick(item, "Vertex", "vertex-index")
              }
            >
              {item}
              {item === "Type your own question below" && (
                <span className="spriteUrl belowIcon"></span>
              )}
            </div>
          ))}
        </>
      )
    }
  ];
  const storenetwork: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          {storeNetworkQues?.map((item, index) => (
            <div
              className={`${
                styles?.singleQue + " " + "d-flex align-items-center"
              } ${isDarkTheme ? "darkSingleQue" : ""}`}
              key={index}
              onClick={() =>
                onSuggestionQNClick(item, "Store Network", "instorecare-index")
              }
            >
              {item}
              {item === "Type your own question below" && (
                <span className="spriteUrl belowIcon"></span>
              )}
            </div>
          ))}
        </>
      )
    }
  ];
  const salesintelligent: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <>
          {salesIntelligenceQues.length > 0 &&
            salesIntelligenceQues?.map((item, index) => (
              <div
                className={`${
                  styles?.singleQue + " " + "d-flex align-items-center"
                } ${isDarkTheme ? "darkSingleQue" : ""}`}
                key={index}
                onClick={() =>
                  onSuggestionQNClick(
                    item,
                    "Sales Intelligent",
                    ""
                  )
                }
              >
                {item}
                {item === "Type your own question below" && (
                  <span className="spriteUrl belowIcon"></span>
                )}
              </div>
            ))}
        </>
      )
    }
  ];
  const handleClick = () => {
    if (!isLoading) {
      clearChat();
    }
  };

  return (
    <div
      className={popoverVisible ? styles?.mainPopEffect : styles?.mainContainer}
    >
      <AppHeader
        userDetails={GetUserName()}
        toggleClick={toggleTheme}
        showBamHeader={false}
      />
      <div className={`app ${isDarkTheme ? "dark" : "light"}`}>
        {GetRoles().includes("User.User") ||
        GetRoles().includes("Users") ||
        GetRoles().includes("BusinessImpact.Users") ? (
          <>
            {!showPrompts ? (
              <div>
                {selectedIndexName && lastQuestionRef?.current ? (
                  <SelectedIndexComp
                    selectedIndexName={selectedIndexName}
                    item={
                      selectedIndexName === "HAI Buddy"
                        ? haiBuddy
                        : selectedIndexName === "Business Impact"
                        ? busiImpact
                        : selectedIndexName === "Vertex"
                        ? vertex
                        : selectedIndexName === "Store Manager"
                        ? strMng
                        : selectedIndexName === "Store Network"
                        ? storenetwork
                        : selectedIndexName === "Sales Intelligent"
                        ? salesintelligent
                        : []
                    }
                    onSampleQNClick={onSampleQNClick}
                    isLoading={isLoading}
                  />
                ) : null}
                <CRow className="mx-0">
                  <CCol
                    md={isSidebarOpen ? 9 : 12}
                    className={`${styles?.container + " " + "px-0"} ${
                      !lastQuestionRef?.current ? styles.chatBackground : ""
                    }`}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-start",
                        padding: "10px"
                      }}
                    >
                      <TooltipHost
                        content="Prompt Gallery"
                        directionalHint={DirectionalHint.bottomCenter}
                      >
                        <FaFolder style={{ marginRight: '10px', cursor: 'pointer' }} onClick={showDrawer}  />
                      </TooltipHost>
                      <Drawer
                        title="Prompt Gallery"
                        placement="right"
                        closable={true}
                        onClose={hideDrawer}
                        visible={drawerVisible}
                        width={400}
                      >
                        <PromptGallery
                          makeApiRequest={(
                            description: string,
                            index: string
                          ) => {
                            makeApiRequest(description,index);
                          }}
                          onHidePrompts={() => {
                            hideDrawer();
                          }}
                        />
                      </Drawer>
                      <TooltipHost
                        content="History"
                        directionalHint={DirectionalHint.bottomCenter}
                      >
                        <img
                          src={historyimg}
                          alt="History"
                          onClick={() => {
                            handleRecommendationClick("History");
                          }}
                        />
                      </TooltipHost>
                    </div>

                    {showAuthMessage ? (
                      <Stack className={styles?.chatEmptyState}>
                        <ErrorCircleRegular
                          className={styles?.chatIcon}
                          style={{ color: "crimson" }}
                        />
                        <h1 className={styles?.chatEmptyStateTitle}>
                          Authentication Not Configured
                        </h1>
                        <h2 className={styles?.chatEmptyStateSubtitle}>
                          This app does not have authentication configured.
                          Please add an identity provider.
                        </h2>
                        <h2 className={styles?.chatEmptyStateSubtitle}>
                          Go to your app in the
                          <a href="https://portal.azure.com/" target="_blank">
                            {" "}
                            Azure Portal{" "}
                          </a>
                          and follow
                          <a
                            href="https://learn.microsoft.com/en-us/azure/app-service/scenario-secure-app-authentication-app-service#3-configure-authentication-and-authorization"
                            target="_blank"
                          >
                            {" "}
                            these instructions
                          </a>
                          .
                        </h2>
                      </Stack>
                    ) : (
                      <Stack horizontal className={styles?.chatRoot}>
                        <div
                          className={
                            styles?.chatContainer + " " + styles?.chatCnt
                          }
                        >
                        {!lastQuestionRef?.current ? (
                          <>
                            <div
                              className={
                                selectedIndexName === ""
                                  ? styles?.ServiceText
                                  : styles?.selectedIndSerTxt
                              }
                            >
                              <span>
                                <img
                                  src={logo}
                                  alt="Logo"
                                  style={{
                                    width: "56px",
                                    height: "auto",
                                    paddingBottom: "25px"
                                  }}
                                />
                              </span>
                              <CRow className={styles.defaultSampleQuestion}>
                                {salesIntelligentPromtQuest.map((item) => (
                                  <>
                                    <CCol
                                      sm={3}
                                      style={{
                                        paddingBottom: "15px",
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center"
                                      }}
                                    >
                                      <TooltipHost content={item?.description}>
                                      <CCard
                                        className={`${styles.sampleQuestionCard} sampleQuestionCard`}
                                        onClick={() =>
                                          makeApiRequest(
                                            item?.description,
                                              ""
                                          )
                                        }
                                      >
                                        <CCardBody
                                          style={{
                                            display: "flex",
                                            alignItems: "center"
                                          }}
                                        >
                                          <CCardText>{item?.title}</CCardText>
                                        </CCardBody>
                                      </CCard>
                                      </TooltipHost>
                                    </CCol>
                                  </>
                                ))}
                              </CRow>
                                <CRow>
                                  <CCol
                                    style={{
                                      paddingBottom: "15px",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignContent: "center"
                                    }}
                                  >
                                    <Button
                                      onClick={() => {
                                        setShowPrompts(true);
                                      }}
                                    >
                                      Browse Prompt Gallery
                                    </Button>
                                  </CCol>
                                </CRow>
                            </div>
                          </>
                        ) : (
                          <div
                            className={
                                styles?.chatMessageStream +
                                " " +
                                styles?.chatBox
                            }
                            style={{
                              marginBottom: isLoading ? "0px" : "0px",
                              padding: "0px 10%"
                            }}
                          >
                            {answers?.map((answer, index) => (
                              <>
                                {answer?.role === "user" ? (
                                  <>
                                    <CRow className="mx-0 mb-3">
                                      <CCol className="px-0"></CCol>
                                      <CCol
                                        md={11}
                                        sm={11}
                                        className={`px-0 ${styles.userMessage}`}
                                      >
                                        <div>
                                          <span
                                            className={`${styles.chatMessageUserMessage} chatMessageUserMessage px-4 py-3`}
                                          >
                                            {
                                              answer.content.split(
                                                "GrammerlyContent"
                                              )[0]
                                            }
                                          </span>
                                        </div>
                                      </CCol>
                                      {/* <CCol
                                        sm={1}
                                        className={`px-0 ${styles.chatUserNameIcon}`}
                                      >
                                        <div
                                          className="chat-UserName"
                                          title={userDetails?.[0]?.userName}
                                        >
                                          {userDetails.length > 0 &&
                                            getInitials(
                                              userDetails?.[0]?.userName
                                            )}
                                        </div>
                                      </CCol> */}
                                    </CRow>
                                  </>
                                ) : answer?.role === "newConversation" ? (
                                  <CRow className="d-flex text-center">
                                    <div className="fw-bolder">
                                      New chat session started
                                    </div>

                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        margin: "10px 0"
                                      }}
                                    >
                                      <span
                                        style={{
                                          flexGrow: 1,
                                          borderBottom: "1px solid #ccc"
                                        }}
                                      ></span>
                                      <span
                                        style={{
                                          padding: "0 10px",
                                          fontSize: "13px"
                                        }}
                                      >
                                        The assistant setup has been updated.
                                        Previous messages won’t be used as
                                        context for new queries.
                                      </span>
                                      <span
                                        style={{
                                          flexGrow: 1,
                                          borderBottom: "1px solid #ccc"
                                        }}
                                      ></span>
                                    </div>
                                  </CRow>
                                ) : answer?.role === "assistant" ? (
                                  <>
                                    <Answer
                                      answer={{
                                        answer: answer?.content,
                                        citations: parseCitationFromMessage(
                                          answers[index - 1]
                                        )
                                      }}
                                        currentquestion={
                                          lastQuestionRef?.current
                                        }
                                      onCitationClicked={(c) =>
                                        onShowCitation(c)
                                      }
                                      selectedindex={"Sales Intelligent"}
                                      answerindex={index}
                                      sql={answer?.sql}
                                      rephrased_user_prompt={
                                        answer?.rephrased_user_prompt
                                      }
                                      overallLatency={answer?.Overall_Latency}
                                      fetchLatency={answer?.fetch_latency}
                                      totalTokens={answer?.total_tokens}
                                      table_names = {answer?.table_names}
                                        db_data_fetched_size_in_tokens={
                                          answer?.db_data_fetched_size_in_tokens
                                        }
                                        query_generation_latency={
                                          answer?.query_generation_latency
                                        }
                                        response_generation_latency={
                                          answer?.response_generation_latency
                                        }
                                        index_classification_latency={
                                          answer?.index_classification_latency
                                        }
                                    />
                                  </>
                                ) : answer?.role === "systemQuestion" ? (
                                  <SystemAnswer
                                    answer={{
                                      answer: answer?.content,
                                      citations: parseCitationFromMessage(
                                        answers[index - 1]
                                      )
                                    }}
                                    currentquestion={lastQuestionRef?.current}
                                      onCitationClicked={(c) =>
                                        onShowCitation(c)
                                      }
                                    onYesClicked={() => {
                                      if (answer?.callback) {
                                        answer?.callback(
                                          answers,
                                          answer?.email
                                        );
                                      }
                                    }}
                                    onNoClicked={() => {
                                      if (answer?.noAnsCallback) {
                                        answer?.noAnsCallback(
                                          answers,
                                          answer?.email
                                        );
                                      }
                                    }}
                                    email={answer?.email ? answer?.email : ""}
                                  />
                                ) : answer?.role === "systemAnswer" ? (
                                  <SystemResponse
                                    answer={{
                                      answer: answer?.content,
                                      citations: parseCitationFromMessage(
                                        answers[index - 1]
                                      )
                                    }}
                                    currentquestion={lastQuestionRef?.current}
                                      onCitationClicked={(c) =>
                                        onShowCitation(c)
                                      }
                                    onYesClicked={() => {
                                      if (answer?.callback) {
                                        answer?.callback();
                                      }
                                    }}
                                    onNoClicked={() => {
                                      if (answer?.noAnsCallback) {
                                        answer?.noAnsCallback(
                                          answers,
                                          answer?.email
                                        );
                                      }
                                    }}
                                    email={answer?.email ? answer?.email : ""}
                                  />
                                ) : null}
                              </>
                            ))}

                            {showLoadingMessage && (
                              <>
                                <div className={styles?.chatMessageUser}>
                                  <div
                                    className={`${styles.chatMessageUserMessage} chatMessageUserMessage`}
                                  >
                                    {lastQuestionRef?.current}
                                  </div>
                                </div>

                                <Answer
                                  answer={{
                                    answer: "Generating answer...",
                                    citations: []
                                  }}
                                  currentquestion={""}
                                  onCitationClicked={() => null}
                                  selectedindex={"Sales Intelligent"}
                                />
                              </>
                            )}

                            <div ref={chatMessageStreamEnd} />
                          </div>
                        )}
                        <div
                          className={
                            isSidebarOpen
                              ? styles?.chatIpHalf
                              : styles?.chatIpWhole
                          }
                        >
                          {isLoading && (
                            <Stack
                              horizontal
                              className={styles?.stopGeneratingContainer}
                              role="button"
                              aria-label="Stop generating"
                              tabIndex={0}
                              onClick={stopGenerating}
                              onKeyDown={(e) =>
                                e?.key === "Enter" || e?.key === " "
                                  ? stopGenerating()
                                  : null
                              }
                            >
                              <SquareRegular
                                className={styles?.stopGeneratingIcon}
                                aria-hidden="true"
                              />
                              <span
                                className={`${styles?.stopGeneratingText} stopGeneratingText`}
                                aria-hidden="true"
                              >
                                Stop generating
                              </span>
                            </Stack>
                          )}
                          <div
                            className={`${
                              lastQuestionRef?.current
                                ? styles?.chatInputContainer
                                : styles?.chatcontainer
                            } `}
                            style={{
                              padding: !isMobile ? "0 10% 0 15%" : "0 2%"
                            }}
                          >
                            <div className={styles.promptMainDiv}>
                              <div
                                className={`${styles?.answerTextButtonDiv} ${styles?.newConvoProperty}`}
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center"
                                }}
                              >
                                <div className="row w-100">
                                  <div className="col-11" >
                                <div>
                                  <CRow>
                                    {answers.length > 0 &&
                                            relatedPrompts.map(
                                              (data, index) => (
                                        <CCol
                                          xs={4}
                                          key={index}
                                          className="pb-2"
                                        >
                                          <TooltipHost content={data}>
                                            <DefaultButton
                                              className={`${styles?.AnswerTexButton} AnswerTexButton`}
                                              onClick={() =>
                                                makeApiRequest(data)
                                              }
                                              disabled={isLoading}
                                              style={{ width: "100%" }} // Ensure button takes up full width of the column
                                            >
                                              <span className="defaultFontFamily truncatedText">
                                                {data}
                                              </span>
                                            </DefaultButton>
                                          </TooltipHost>
                                        </CCol>
                                              )
                                            )}
                                  </CRow>
                                </div>
                                </div>
                                <div className="col-1">
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    paddingLeft: "2rem"
                                  }}
                                >
                                  <div
                                    className="GramerlyPopDiv"
                                    ref={popoverRef}
                                    style={{
                                            pointerEvents: isLoading
                                              ? "none"
                                              : "auto"
                                    }}
                                  >
                                    <TooltipHost content="Grammarly Configurations">
                                      <span
                                        className={`${styles.gramerlyicon} gramerlyicon`}
                                        onClick={() =>
                                          setGrammarlyPopVisible(true)
                                        }
                                      ></span>
                                    </TooltipHost>
                                    <CPopover
                                      content={
                                        <GramerlyContent
                                          repostQuestion={repostQuestion}
                                        />
                                      }
                                      placement="top"
                                            className={`${
                                              styles?.gramerlyPopover
                                            } ${
                                              isDarkTheme
                                                ? "darkgramerlyPopover"
                                                : ""
                                      }`}
                                      visible={grammarPopVisible}
                                      onHide={() =>
                                        setGrammarlyPopVisible(false)
                                      }
                                    >
                                      <span></span>
                                    </CPopover>
                                  </div>

                                  {!isMute ? (
                                    <TooltipHost content="Mute Speech">
                                      <Speaker228Filled
                                        className={`${
                                          styles?.Speaker + " " + "ms-2"
                                        } Speaker228Filled`}
                                        onClick={() => stopSpeaking()}
                                      ></Speaker228Filled>
                                    </TooltipHost>
                                  ) : (
                                    <TooltipHost content="Unmute Speech">
                                      <SpeakerMute28Regular
                                        onClick={() => setIsMute(false)}
                                        className={`${
                                          styles?.Speaker + " " + "ms-2"
                                        } SpeakerMute28Regular`}
                                      ></SpeakerMute28Regular>
                                    </TooltipHost>
                                  )}
                                </div>
                                </div>
                                </div>
                              </div>
                            </div>
                            <div className={styles?.bottomDiv}>
                              <TooltipHost
                                content="New Conversation"
                                className="newConversationbottom"
                              >
                                <img
                                  className="newConversationbottom"
                                  src={newconversationimg}
                                  alt="New Conversation"
                                  onClick={handleClick}
                                  // Disable the button if isLoading is true
                                  // disabled={isLoading}
                                />
                              </TooltipHost>
                              <QuestionInput
                                clearOnSend
                                placeholder="Message MAG-AI Companion..."
                                disabled={isLoading}
                                  onSend={(question) =>
                                    makeApiRequest(question)
                                  }
                                voicedisabled={
                                  (isSpeechStarted && !isMute) || isLoading
                                }
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      {answers.length > 0 &&
                        isCitationPanelOpen &&
                        !isMobile &&
                        activeCitation && (
                          <Stack.Item className={styles?.citationPanel}>
                            <Stack
                              horizontal
                              className={styles?.citationPanelHeaderContainer}
                              horizontalAlign="space-between"
                              verticalAlign="center"
                            >
                              <span className={styles?.citationPanelHeader}>
                                Citations
                              </span>
                              <DismissRegular
                                className={styles?.citationPanelDismiss}
                                onClick={() => setIsCitationPanelOpen(false)}
                              />
                            </Stack>
                            <h5 className={styles?.citationPanelTitle}>
                              {activeCitation[2]}
                            </h5>
                            <ReactMarkdown
                              linkTarget="_blank"
                              className={styles?.citationPanelContent}
                              children={activeCitation[0]}
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                            />
                          </Stack.Item>
                        )}
                      {isMobile && answers.length > 0 && activeCitation && (
                        <CModal
                          visible={isCitationPanelOpen}
                          onClose={() => setIsCitationPanelOpen(false)}
                        >
                            <Stack.Item
                              className={` ${mobstyles?.mobCitation}`}
                            >
                            <Stack
                              horizontal
                              className={`${styles?.citationPanelHeaderContainer} ${mobstyles?.mobhdrcnt}`}
                              horizontalAlign="space-between"
                              verticalAlign="center"
                            >
                              <span className={styles?.citationPanelHeader}>
                                Citations
                              </span>
                              <DismissRegular
                                className={styles?.citationPanelDismiss}
                                onClick={() => setIsCitationPanelOpen(false)}
                              />
                            </Stack>
                            <h5 className={styles?.citationPanelTitle}>
                              {activeCitation[2]}
                            </h5>
                            <ReactMarkdown
                              linkTarget="_blank"
                              className={`${styles?.citationPanelContent} ${
                                mobstyles?.panelContent
                              } ${"citationPanelContent"}`}
                              children={activeCitation[0]}
                              remarkPlugins={[remarkGfm]}
                              rehypePlugins={[rehypeRaw]}
                            />
                          </Stack.Item>
                        </CModal>
                      )}
                    </Stack>
                  )}
                  <div>
                    <Modal
                      titleAriaId="modalTitle"
                      isOpen={isModalOpen}
                      onDismiss={hideModal}
                      isBlocking={false}
                      styles={{
                        main: { width: 800 },
                        scrollableContent: { padding: 20 }
                      }}
                    >
                      <div>
                        <h2 id="modalTitle">Send Email</h2>
                      </div>

                      <div>
                        <Stack tokens={{ childrenGap: 10 }}>
                          <Stack horizontal tokens={{ childrenGap: 20 }}>
                            <TextField
                              label="SMTP Provider"
                              required
                              defaultValue={smtpProvider}
                              onChange={(e, newValue) =>
                                setSmtpProvider(newValue || "")
                              }
                            />
                            <TextField
                              label="Sender EmailID"
                              required
                              defaultValue={senderEmailID}
                              onChange={(e, newValue) =>
                                setSenderEmailID(newValue || "")
                              }
                            />
                            <TextField
                              type="password"
                              label="App Password"
                              required
                              defaultValue={appPassword}
                              onChange={(e, newValue) =>
                                setAppPassword(newValue || "")
                              }
                            />
                            <TextField
                              label="Receiver EmailID"
                              required
                              defaultValue={receiverEmailID}
                              onChange={(e, newValue) =>
                                setReceiverEmailID(newValue || "")
                              }
                            />
                          </Stack>
                          <TextField
                            label="Subject"
                            required
                            defaultValue={subject}
                            onChange={(e, newValue) =>
                              setSubject(newValue || "")
                            }
                          />
                          <TextField
                            label="Content"
                            required
                            multiline
                            rows={10}
                            defaultValue={content}
                            onChange={(e, newValue) =>
                              setContent(newValue || "")
                            }
                          />
                        </Stack>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "20px"
                        }}
                      >
                        <PrimaryButton
                          onClick={onSend}
                          style={{ marginRight: "10px" }}
                        >
                          Send
                        </PrimaryButton>
                        <DefaultButton onClick={hideModal}>
                          Cancel
                        </DefaultButton>
                      </div>
                    </Modal>
                  </div>
                  <div>
                    <Modal
                      titleAriaId="psmodalTitle"
                      isOpen={isPsModalOpen}
                      onDismiss={hidePsModal}
                      isBlocking={false}
                      styles={{
                        main: { width: 800 },
                        scrollableContent: { padding: 20 }
                      }}
                    >
                      <div>
                        <h2 id="modalTitle">Execute PowershellScript</h2>
                      </div>

                      <div>
                        <Stack tokens={{ childrenGap: 10 }}>
                          <TextField
                            label="Script"
                            required
                            multiline
                            rows={10}
                            defaultValue={psscript}
                            onChange={(e, newValue) =>
                              setPsScript(newValue || "")
                            }
                          />
                        </Stack>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginTop: "20px"
                        }}
                      >
                        <PrimaryButton
                          onClick={onExecute}
                          style={{ marginRight: "10px" }}
                        >
                          Execute
                        </PrimaryButton>
                        <DefaultButton onClick={hidePsModal}>
                          Cancel
                        </DefaultButton>
                      </div>
                    </Modal>
                  </div>
                </CCol>
                {isSidebarOpen && !isMobile && (
                  <CCol md={3} className="px-0">
                    <RecommendationTab
                      historydata={groupedData}
                      historyOnlickfunction={setHistoryToCurrent}
                    />
                  </CCol>
                )}
              </CRow>
            </div>
            ) : (
              <div>
                <PromptGallery
                  makeApiRequest={(description: string, index: string) => {
                    makeApiRequest(description,index);
                  }}
                  onHidePrompts={() => {
                    setShowPrompts(false);
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <div className={styles.verticalcenter}>
            <div
              role="alert"
              className="text-center w-100"
              data-testid="errorFallback"
            >
              <div className="mb-1">Access Denied</div>
              <pre>You don't have access to this application</pre>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SalesChat;
interface SampleQuestionsProps {
  item: MenuProps["items"];
  onSampleQNClick: (item: {
    title: string;
    indexName?: string;
    index: string;
    description: string;
  }) => void;
}
export const SampleQuestionsDropDown: React.FC<SampleQuestionsProps> = ({
  item,
  onSampleQNClick
}) => {
  const { sampleQnPopVisible, setSampleQnPopVisible } = useAppContext();
  // Component implementation
  return (
    <div className={styles?.dropdown}>
      <div className={styles.ModalCloseBtn}>
        <span
          onClick={() => {
            setSampleQnPopVisible(false);
          }}
          className={`${styles.spriteUrl} ${styles.modalCloseIcon}`}
        ></span>
      </div>
      <Dropdown
        className={"SamQueDropdown"}
        menu={{
          items: item
        }}
      >
        <div className="dropdown">
          <div
            className={`${styles?.griditem} ${"dropbtn"}`}
            // onClick={() => onSampleQNClick(item)}
          ></div>
        </div>
      </Dropdown>
    </div>
  );
};
interface SelectedIndexCompProps {
  selectedIndexName: string;
  item?: MenuProps["items"];
  onSampleQNClick: (item: {
    title: string;
    indexName?: string;
    index: string;
    description: string;
  }) => void;
  isLoading?: boolean;
}

export const SelectedIndexComp: React.FC<SelectedIndexCompProps> = ({
  selectedIndexName,
  item,
  onSampleQNClick,
  isLoading
}) => {
  const { sampleQnPopVisible, setSampleQnPopVisible } = useAppContext();
  return (
    <>
      {item && (
        <div className={styles?.selectedIndexMainDiv}>
          <div className={styles?.selectedIndex_Row}>
            <span className={styles?.selected_index}>{selectedIndexName}</span>

            {selectedIndexName != "Sales Intelligent" && (
              <span className={styles?.selectedIndexIcon}>
                <Dropdown
                  className={"SamQueDropdown"}
                  menu={{
                    items: item
                  }}
                >
                  <div
                    className="dropdown"
                    style={{ pointerEvents: isLoading ? "none" : "auto" }}
                  >
                    {/* <div
                className={`${styles?.griditem} ${"dropbtn"}`}
                // onClick={() => onSampleQNClick(item)}
              ></div> */}
                    <span
                      className={styles.gramerlyicon}
                      onClick={() => {
                        setSampleQnPopVisible(true);
                      }}
                    ></span>
                  </div>
                </Dropdown>
              </span>
            )}
          </div>
        </div>
      )}
    </>
  );
};
